import { bootstrap } from '@central-products/bootstrapper';
import MessageBusTransport from '@central-products/message-transport-bus';
import { init } from './init';

const krakenConfig = window.kraken;
__webpack_public_path__ = krakenConfig.productConfig.publicPath; // eslint-disable-line camelcase, no-undef

init({
  krakenConfig,
  bootstrap: (bootstrapperFeatures, bootstrapperConfig) => {
    const { init: initBootstrapper, features: initializedBootstrapperFeatures } = bootstrap(
      MessageBusTransport.getInstance(),
      bootstrapperFeatures,
      bootstrapperConfig
    );
    return { initBootstrapper, initializedBootstrapperFeatures };
  },
}).catch(console.error);
