const isAbortControllerSupported = () => {
  const requestIsFunction = typeof window.Request === 'function';
  const requestHasSignalProperty = Object.prototype.hasOwnProperty.call(window.Request, 'signal');

  return (requestIsFunction && requestHasSignalProperty) || window.AbortController;
};

export const loadPolyfills = async () => {
  const requiredPolyfills = [];

  if (!isAbortControllerSupported()) {
    requiredPolyfills.push(
      import(/* webpackChunkName: "polyfills" */ 'abortcontroller-polyfill/dist/polyfill-patch-fetch')
    );
  }

  return Promise.all(requiredPolyfills);
};
