import React from 'react';
import ReactDOM from 'react-dom';
import { publish, publishSticky, subscribe } from '@wh/message-bus';
import { APP_ROOT_ID, HEADER_BASE_CONFIG } from '../constants';
import { makePluginsRunner } from '../pluginsRunner';
import { handleNavigationRequests } from './handleNavigationRequests';
import { loadPolyfills } from './loadPolyfills';
import { getDeviceType, getValueForDevice } from '../utils';
import { App } from './App';
import { pluginsRegistry } from '../plugins-registry';

export const init = async ({ krakenConfig, bootstrap }) => {
  const { productConfig, content, locale, headerType, plugins, productSource, vertical, headerless } = krakenConfig;
  const headerRenderers = [];

  await loadPolyfills();

  const bootstrapperFeatures = [];
  const bootstrapperConfig = {};

  const pluginsRunner = makePluginsRunner({
    productSource: getValueForDevice(productSource),
    plugins,
    productConfig,
    locale,
    deviceType: getDeviceType(),
    headerType,
    header: HEADER_BASE_CONFIG,
    bootstrapperFeatures,
    bootstrapperConfig,
    headerRenderers,
    vertical,
    pluginsRegistry,
  });

  pluginsRunner.beforeBootstrap();

  const { initBootstrapper, initializedBootstrapperFeatures } = bootstrap(bootstrapperFeatures, bootstrapperConfig);

  initBootstrapper();
  publishSticky('wh.kraken.event.boostrapped');
  handleNavigationRequests(initializedBootstrapperFeatures);

  // temporary fix to open deposit in native apps without full kraken header, forwarding proper event to native
  if (vertical === 'sportsbook-native') {
    subscribe({
      topic: 'wh.common.event.ui.header.deposit.requested',
      callback: ({ data }) => {
        publish('myaccount_overlay_show_deposit', data);
      },
    });
  }

  pluginsRunner.postBootstrap({ initializedBootstrapperFeatures });

  if (headerless) return;

  pluginsRunner.beforeRender();

  ReactDOM.render(
    <App
      headerType={headerType}
      headerConfig={HEADER_BASE_CONFIG}
      content={content}
      headerRenderers={headerRenderers}
    />,
    document.getElementById(APP_ROOT_ID)
  );

  pluginsRunner.afterRender();
};
