import Cookies from 'js-cookie';

export const isNative = () => {
  try {
    const deviceCookie = Cookies.get('wh_device');
    const parsedDeviceCookie = JSON.parse(deviceCookie);
    return parsedDeviceCookie.is_native === true || parsedDeviceCookie.is_native === 'true';
  } catch (error) {
    return false;
  }
};

export const isIos = () => {
  try {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  } catch (error) {
    return false;
  }
};

export const isAndroid = () => {
  try {
    return /(Android|; wv|Version\/\d+.*\/\d+.0.0.0)/i.test(navigator.userAgent);
  } catch (error) {
    return false;
  }
};
