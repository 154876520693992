import { getDeviceType } from './getDeviceType';

export const getValueForDevice = (value) => {
  if (typeof value === 'object') {
    const device = getDeviceType();
    return value[device] || value.mobile || value;
  }

  return value;
};
