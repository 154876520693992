import { publish } from '@wh/message-bus';
import { pubSub } from './utils/pubSub';
import { isNative } from './utils/isNative';
import { featuresConfigs } from './bootstrapper';
import { HEADER_PROPS_UPDATE_REQUESTED } from './constants';

const noop = () => {};

function filterPlugins(plugins, { deviceType }) {
  const key = isNative() ? `${deviceType}Native` : deviceType;
  return plugins.filter((p) => !p[2] || p[2][key]);
}

export const makePluginsRunner = ({
  productSource,
  vertical,
  plugins: allPlugins,
  productConfig,
  locale,
  deviceType,
  headerType,
  header,
  bootstrapperFeatures,
  bootstrapperConfig,
  headerRenderers,
  pluginsRegistry,
}) => {
  Object.keys(pluginsRegistry).forEach((pluginName) => {
    const pluginImplementation = pluginsRegistry[pluginName];
    pluginImplementation.beforeBootstrap = pluginImplementation.beforeBootstrap || noop;
    pluginImplementation.postBootstrap = pluginImplementation.postBootstrap || noop;
    pluginImplementation.beforeRender = pluginImplementation.beforeRender || noop;
    pluginImplementation.afterRender = pluginImplementation.afterRender || noop;
  });

  const addBootstrapperFeature = (feature, verticalConfig = {}) => {
    if (!bootstrapperFeatures.find(({ name }) => name === feature.name)) {
      bootstrapperFeatures.push(feature);
    }

    const getFeatureConfig = featuresConfigs[feature.name];

    bootstrapperConfig[feature.name] = getFeatureConfig({
      productSource,
      vertical,
      locale,
      deviceType,
      headerType,
      productConfig,
      ...verticalConfig,
    });
  };

  const updateHeaderProps = (state) => publish(HEADER_PROPS_UPDATE_REQUESTED, state);
  const addHeaderRenderers = (renderers) => pubSub.publish({ renderers });

  const plugins = filterPlugins(allPlugins, { deviceType });
  return {
    beforeBootstrap() {
      plugins.forEach((plugin) => {
        const pluginName = plugin[0];
        const pluginImplementation = pluginsRegistry[pluginName];

        pluginImplementation.beforeBootstrap({
          productConfig,
          locale,
          deviceType,
          headerType,
          addBootstrapperFeature,
          params: plugin[1],
          addHeaderRenderers,
          updateHeaderProps,
        });
      });
    },

    postBootstrap({ initializedBootstrapperFeatures }) {
      plugins.forEach((plugin) => {
        const pluginName = plugin[0];
        const pluginImplementation = pluginsRegistry[pluginName];

        pluginImplementation.postBootstrap({
          productConfig,
          locale,
          deviceType,
          headerType,
          bootstrapperFeatures: initializedBootstrapperFeatures,
          params: plugin[1],
          addHeaderRenderers,
          updateHeaderProps,
        });
      });
    },

    beforeRender() {
      plugins.forEach((plugin) => {
        const pluginName = plugin[0];
        const pluginImplementation = pluginsRegistry[pluginName];

        pluginImplementation.beforeRender({
          productConfig,
          locale,
          deviceType,
          headerType,
          header,
          headerRenderers,
          params: plugin[1],
          addHeaderRenderers,
          updateHeaderProps,
        });
      });
    },

    afterRender() {
      plugins.forEach((plugin) => {
        const pluginName = plugin[0];
        const pluginImplementation = pluginsRegistry[pluginName];

        pluginImplementation.afterRender({
          productConfig,
          locale,
          deviceType,
          headerType,
          header,
          headerRenderers,
          params: plugin[1],
          addHeaderRenderers,
          updateHeaderProps,
        });
      });
    },
  };
};
