import { publishSticky } from '@wh/message-bus';

export const publishProductSelect = (item) => {
  publishSticky('wh.common.command.ui.header.product.select', {
    id: `product-menu-item-${item.label}`,
  });
};

export const getPathnameParts = (pathname) => pathname.substring(1).split('/');

export const onLocationChange = (callback) => {
  if (!history || !window) return;

  const { pushState, replaceState } = history;

  history.pushState = function () {
    pushState.apply(history, arguments);
    window.dispatchEvent(new Event('pushstate'));
    callback();
  };

  history.replaceState = function () {
    replaceState.apply(history, arguments);
    window.dispatchEvent(new Event('replacestate'));
    callback();
  };

  window.addEventListener('popstate', callback);
};

export const selectActiveItem = (parsedItems) => {
  if (!window) return;

  const { hostname, pathname, protocol } = window.location;

  const matchedItems = parsedItems.filter(({ url }) =>
    `${protocol}//${hostname}${pathname}`.includes(url.replace(/\/*$/, ''))
  );

  // only one match -> publish product select
  if (matchedItems.length === 1) {
    publishProductSelect(matchedItems[0]);
  }
  // multiple match (same domain, but different pathnames) -> find the best item and then publish product select
  else if (matchedItems.length > 1) {
    const pathnameParts = getPathnameParts(pathname);
    const bestScore = matchedItems
      .map(({ url }, itemIdx) => {
        const itemPathnameParts = getPathnameParts(new URL(url.replace(/\/*$/, '')).pathname);
        const itemScore = pathnameParts
          .map((pathnamePart, pathnameIdx) => (pathnamePart === itemPathnameParts[pathnameIdx] ? 1 : 0))
          .reduce((prev, curr) => prev + curr, 0);

        return { itemScore, itemIdx };
      })
      .sort((a, b) => b.itemScore - a.itemScore)[0];

    publishProductSelect(matchedItems[bestScore.itemIdx]);
  }
};
