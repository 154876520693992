import PropTypes from 'prop-types';
import React from 'react';
import { throttle } from 'throttle-debounce';
import { AssetsProvider } from '@sitebase/assets-context';
import { MediaProvider } from '@sitebase/media-context';
import Header from '@sitebase/pre-integrated-header';
import { initWorkflows, renderers as piHeaderRenderers } from '@sitebase/pre-integrated-header/default-features';
import { publish, subscribe } from '@wh/message-bus';
import American from '@wh/svg-icons/American';
import Chat from '@wh/svg-icons/Chat';
import Contactus from '@wh/svg-icons/Contactus';
import Decimal from '@wh/svg-icons/Decimal';
import Email from '@wh/svg-icons/Email';
import Feedback from '@wh/svg-icons/Feedback';
import Fraction from '@wh/svg-icons/Fraction';
import Greyhounds from '@wh/svg-icons/Greyhounds';
import HelpCircle from '@wh/svg-icons/HelpCircle';
import HorseRacing from '@wh/svg-icons/HorseRacing';
import Podcast from '@wh/svg-icons/Podcast';
import Radio from '@wh/svg-icons/Radio';
import Tv from '@wh/svg-icons/Tv';
import { TranslatorProvider } from '@wh/ui-translations';
import { HEADER_PROPS_UPDATE_REQUESTED, MB_EVENT_HAMBURGER_CLICKED, HEADER_TYPE_CHANGED } from '../constants';
import { pubSub } from '../utils/pubSub';
import './styles/App.scss';

export class App extends React.PureComponent {
  state = {
    headerType: this.props.headerType,
    headerProps: { collapsed: false, shifted: false, hamburgerState: 'inactive' },
    asyncRenderers: [],
  };

  componentDidMount() {
    initWorkflows({ disableQuickDeposit: !window.kraken.quickDepositEnabled });
    subscribe(HEADER_PROPS_UPDATE_REQUESTED, (topic, headerProps) => {
      this.setState({ headerProps: { ...this.state.headerProps, ...headerProps } });
    });
    // custom pubSub to publish payload which contains function
    pubSub.subscribe(({ renderers }) => {
      const asyncRenderers = this.state.asyncRenderers.concat(renderers);
      this.setState({ asyncRenderers });
    });
    this.updateLayout();
    window.addEventListener('resize', this.updateLayout);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateLayout);
  }

  updateLayout = throttle(100, () => {
    let headerType;
    if (window.innerWidth < 640) {
      headerType = 'mobile';
    } else {
      headerType = 'desktop';
    }

    if (this.state.headerType !== headerType) {
      this.setState({ headerType });
      publish(HEADER_TYPE_CHANGED, {});
    }
  });

  onHamburgerClick = () => publish(MB_EVENT_HAMBURGER_CLICKED, {});

  render() {
    const { content, headerConfig, headerRenderers } = this.props;
    const { headerType, headerProps, asyncRenderers } = this.state;

    return (
      <TranslatorProvider translations={content}>
        <AssetsProvider resolver={App.assetsProviderResolver}>
          <MediaProvider device={headerType}>
            <Header
              type={headerType}
              config={headerConfig}
              onHamburgerClick={this.onHamburgerClick}
              renderers={[...headerRenderers, ...piHeaderRenderers, ...asyncRenderers]}
              {...headerProps}
            />
          </MediaProvider>
        </AssetsProvider>
      </TranslatorProvider>
    );
  }
}

App.propTypes = {
  headerType: PropTypes.string,
  content: PropTypes.shape({}),
  headerConfig: PropTypes.shape({}),
  headerRenderers: PropTypes.arrayOf(PropTypes.shape({})),
};

App.assetsProviderResolver = function assetsProviderResolver(name) {
  const ALLOWED_ICONS = {
    American,
    Chat,
    Contactus,
    Decimal,
    Email,
    Feedback,
    Fraction,
    Greyhounds,
    HelpCircle,
    HorseRacing,
    Podcast,
    Radio,
    Tv,
  };
  return ALLOWED_ICONS[name];
};
