import { onLocationChange, selectActiveItem } from './utils';

export const productMenuPlugin = {
  beforeRender({ header, params, content }) {
    if (process.env.RUNTIME === 'browser') {
      selectActiveItem(params.items, content);
    }

    Array.isArray(params.items) &&
      (header.productNav = {
        type: '@sitebase/product-menu',
        data: {
          id: 'hrs-product-nav',
        },
        children: params.items.map((item) => ({
          type: '@sitebase/product-menu-item',
          data: {
            label: item.label,
            id: `product-menu-item-${item.label}`,
            link: {
              url: item.url,
            },
            redirectToProduct: params.redirectToProduct,
          },
        })),
      });
  },
  afterRender({ params, content }) {
    onLocationChange(() => selectActiveItem(params.items, content));
  },
};
