export const openLink = (link = {}) => {
  if (!link.url || typeof link.url !== 'string') return;

  const { url, target = '_self' } = link;

  const newWindow = window.open(url, target);

  // Safari/IOS "window.open" is blocked and returns null
  if (!newWindow) {
    window.location.assign(url);
  } else {
    newWindow.focus();
  }
};
