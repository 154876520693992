class PubSub {
  constructor() {
    this.subscribers = [];
  }

  publish(payload) {
    this.subscribers.forEach((s) => s(payload));
  }

  subscribe(subscriber) {
    this.subscribers.push(subscriber);
  }
}

export const pubSub = new PubSub();
