export const APP_ROOT_ID = 'header-root';
export const MB_EVENT_HAMBURGER_CLICKED = 'wh.header.event.hamburger.clicked';
export const HEADER_PROPS_UPDATE_REQUESTED = 'wh.header.piHeaderPropsUpdate.requested';
export const HEADER_TYPE_CHANGED = 'wh.header.headerType.changed';
export const HEADER_BASE_CONFIG = {
  actions: {
    type: '@sitebase/action-area',
    data: {
      id: 'hrs-action-area',
    },
    children: [],
  },
};
export const ANDROID_SPAIN_SPORTSBOOK_NATIVE_APP_USER_AGENT = 'd6596ce2-c402-4efa-950b-90c3646767ec';
