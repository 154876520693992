import { subscribe } from '@wh/message-bus';
import { openLink } from '../../utils';

export const logoPlugin = {
  beforeRender({ header, params }) {
    if (process.env.RUNTIME === 'browser') {
      subscribe('wh.common.event.ui.header.logo.requested', (_topic, data) => {
        openLink(data.link);
      });
    }

    header.logo = {
      type: '@sitebase/logo',
      data: {
        id: 'hrs-logo',
        link: {
          id: 'hrs-logo',
          url: params.href,
        },
      },
    };
  },
};
